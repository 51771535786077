@import 'shared/styles/global-imports';

.menu {
  fill: $clr-palette-primary;
}

.menuItem {
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-bottom-color 0.3s;
  color: $clr-palette-white;

  &:hover,
  &:active,
  &:focus {
    border-bottom-color: $clr-palette-primary;
    color: $clr-palette-primary;
    text-decoration: none;
  }
}

.navItemActive {
  border-bottom-color: $clr-palette-primary;
  color: $clr-palette-primary;
}
