@import 'shared/styles/global-imports';

.rootWrapper {
  h4 {
    padding-left: 15px;
    height: 28px;
    font-weight: $font-weight-regular;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.menu {
  width: 256px;

  &:global(.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover) {
    background-color: $clr-palette-white;
  }

  & :global {
    .ant-menu-title-content {
      user-select: none;
    }

    .ant-menu-submenu-title {
      font-size: 16px;
    }
  }
}

.navMenu {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  transition: all 3s;

  &.closeArrow {
    transform: rotate(0deg);
  }

  &.openArrow {
    transform: rotate(90deg);
  }
}
