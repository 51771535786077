@import 'shared/styles/global-imports';

.wrapperContent {
  height: 100%;
}

.tableWrapper {
  position: relative;
  height: 100%;
}

