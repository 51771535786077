@import 'shared/styles/global-imports';

.menu:global(.ant-menu) {
  background-color: transparent;
}

.menuTitle {
  font-weight: 400;
  margin: 0;
}

.antDropdown{
  :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background-color: #fffaf0;// TODO: REPLACE-FROM-NEW-PALETTE
    border-radius: 6px;
  }

  :global(.ant-menu-vertical.ant-menu-sub:not([class*=-active])){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;

    :global(.ant-menu-item-disabled){
      color: #000000!important;// TODO: REPLACE-FROM-NEW-PALETTE
      font-weight: 500;
    }
  }

  :global(.ant-menu-vertical .ant-menu-item){
    margin-top: 0!important;
    margin-bottom: 0!important;
  }
}

.languageButton {
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  &:hover {
    background-color: #fffaf0;// TODO: REPLACE-FROM-NEW-PALETTE
  }
}

.translationIcon {
  font-size: 20px;
}
