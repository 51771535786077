@import 'shared/styles/global-imports';

.rootWrapper {
  max-width: 180px;
  height: 100%;
  width: 100%;
  border-left: 1px solid $clr-palette-extra-light-gray;
  padding: 16px;
}

.columnHeader {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 2.5;
}

.topHeaderContentLeft {
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;
  max-height: 24px;

  & .groupsTitle {
    flex: 2;
  }
}

.pinButton {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  padding: 0 6px;
  width: 24px;

  & .unpinned path:first-child {
    fill: $clr-palette-dark-gray;
  }

  & .pinned path {
    fill: $clr-palette-primary;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;

    path {
      fill-opacity: 0.2;
    }
  }
}

.divider {
  justify-content: center;
  align-items: center;

  &:global(.ant-divider-horizontal) {
    margin: 16px 0 11px;
  }
}

.contentItemWrapper {
  margin-bottom: 12px;
}

.groupWidgetWrapper {
  display: flex;
  align-items: center;
  height: 40px;
}

.groupItemsWrapper {
  display: flex;
  flex-direction: column;
}

.groupItemWidgetWrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  height: 40px;
}

.groupItemWidgetWrapper:only-child {
  margin-bottom: 4px;
}

.emptyRow {
  height: 56px;
}

.groupsTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuOption {
  top: 0;
  right: 0;
  padding-left: 4px;
  overflow: hidden;
}

.columnBorderTop {
  padding-top: 12px;
  border-top: 4px solid $clr-palette-primary;
}

.columnErrorBorderTop {
  padding-top: 12px;
  border-top: 4px solid $clr-palette-error;
}
