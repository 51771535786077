@import url(~sanitize.css);

@import 'shared/styles/base/fonts';

@import 'shared/styles/color-palette';
@import 'shared/styles/color-palette-new';
@import 'shared/styles/variables';
@import 'shared/styles/base/helpers';

@import 'shared/styles/base/global';
@import 'shared/styles/base/typography';
