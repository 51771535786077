@import 'shared/styles/global-imports';

.usersCount {
  margin-bottom: $spacer-s;
  font-weight: 400;

  & :global {
    .ant-skeleton-content > .ant-skeleton-title,
    .ant-skeleton-content > .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-content {
      height: calc($line-height-base * $font-size-regular);
    }
  }
}
