.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  div {
    font-size: 80px;
  }

  p {
    font-size: 30px;
    padding-top: 25px;
  }
}
