@import 'shared/styles/global-imports';

.formItemsWrapper {
  width: 500px;
  flex-direction: column;
  justify-content: space-between;
}

.nameInput, .descriptionInput {
  width: 500px;
}
