@import 'shared/styles/global-imports';

$user-overview-widget-list-heading-size: 25px;

.rootWrapper {
  padding: 16px 8px 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.heading {
  margin-bottom: 16px;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingText {
  font-weight: $font-weight-normal;
}

.listWrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden ;
}

.itemContainer {
  position: relative;
  min-width: 650px;
  height: calc(100% - $user-overview-widget-list-heading-size);
}
