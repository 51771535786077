@import 'shared/styles/base/helpers';

//****************************************
//   Variables
//****************************************

// 1 - Color
// 2 - Font
// 3 - Grid and wrapper
// 4 - Spacing
// 5 - Breakpoints
// 6 - Transitions

// ---------------------------------------
// 1 - Color
// ---------------------------------------
$clr-body-bg: $clr-palette-white;
$clr-body-color: $clr-palette-black;
$clr-palette-link-blue: hsl(218, 89%, 63%);
$clr-body-bg-light-gray: hsl(0, 0%, 94%);
// ---------------------------------------
// 2 - Font
// ---------------------------------------

$font-family-base: 'Inter', sans-serif;

// $font-weight-bold: bold;
$font-weight-regular: 500;
$font-weight-normal: 400;
// $font-weight-thin: 100;

$font-weight-base: $font-weight-regular;

$font-size-base-value: 14; // raw value for usage in pxToEm helper

$font-size-small: 12;
$font-size-regular: $font-size-base-value;
$font-size-medium: 16;
$font-size-large: 18;

$line-height-l: 1.75;
// $line-height-m: 1.572;
$line-height-s: 1.25;
// $line-height-xs: 1;

$line-height-base: $line-height-s;

// ---------------------------------------
// 3 - Grid and wrapper
// ---------------------------------------

$page-min-width: 320px;
// $page-max-width: 1210px;
// $columns-gutter: 20px;
// $container-gutter: 20px;

// ---------------------------------------
// 4 - Spacing
// ---------------------------------------

$spacer-s: 10px;
$spacer-m: 24px;
$spacer-l: 40px;
$spacer-xl: 56px;

// ---------------------------------------
// 5 - Breakpoints (include-media)
// ---------------------------------------

$breakpoints: (
  // 'screen-phone-s': 320px,
  'screen-phone': 480px,
  'screen-tablet': 768px,
  'screen-desktop': 1024px,
  // 'screen-widescreen': 1200px,
);

// ---------------------------------------
// 6 - Transitions
// ---------------------------------------

$animation-speed: 0.3s;
$transition: all $animation-speed ease-in-out;

// ---------------------------------------
// 7 - Typography
// ---------------------------------------

$clr-link-base: $clr-palette-primary;
$clr-link-hover-base: $clr-palette-dark-primary;
$clr-link-disabled: $clr-palette-extra-light-primary;

$clr-partition-base: $clr-body-color;
$clr-list-base: $clr-body-color;
$clr-list-item-base: $clr-body-color;
// $vertical-rhythm: ($line-height-base * 1em); // TODO

// ---------------------------------------
// 8 - Headings
// ---------------------------------------

// TODO: change
// $h1-font-size-s: pxToRem(32);
// $h2-font-size-s: pxToRem(24);
// $h3-font-size-s: pxToRem(20);
// $h4-font-size-s: pxToRem(20);
// $h5-font-size-s: pxToRem(20);
// $h6-font-size-s: pxToRem(20);

$h1-font-size: 40;
$h2-font-size: 32;
$h3-font-size: 24;
$h4-font-size: 20;
$h5-font-size: 16;
$h6-font-size: 10; // TODO: change

// TODO: change
// $h1-font-size-l: pxToRem(32);
// $h2-font-size-l: pxToRem(24);
// $h3-font-size-l: pxToRem(20);
// $h4-font-size-l: pxToRem(20);
// $h5-font-size-l: pxToRem(20);
// $h6-font-size-l: pxToRem(20);

$headings-font-family: $font-family-base;
$headings-line-height: 1.5;
$clr-headings-base: $clr-body-color;

$scrollbar-width: 8px;
$scrollbar-border-radius: 2px;
$clr-scrollbar-handle: $clr-palette-light-primary;
$clr-scrollbar-handle-hover: $clr-palette-primary;
$clr-scrollbar-track: $clr-palette-extra-light-primary;
$clr-scrollbar-track-hover: $clr-palette-light-warning;
// ---------------------------------------
// 9 - Ant
// ---------------------------------------

// Ant Layout
$layout-body-background: transparent;
// $layout-header-background: todo;
// $layout-footer-background: todo;

$page-top-gap-l: $spacer-l;
$page-side-gap-l: $spacer-l;
$page-bottom-gap-l: $spacer-l;

$page-top-gap-m: $spacer-m;
$page-side-gap-m: $spacer-m;
$page-bottom-gap-m: $spacer-m;

$page-top-gap-s: $spacer-s;
$page-side-gap-s: $spacer-s;
$page-bottom-gap-s: $spacer-s;

