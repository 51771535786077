.overlay {
  visibility: collapse;

  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  transition: visibility .25s ease-in-out .1s;

  &.visible {
    visibility: visible;
  }
}

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  background-color: white;
  
  transition: opacity .25s ease-in-out .1s;

  .overlay.visible & {
    opacity: .9;
  }
}

.content {
  height: 100%;
  width: 600px;
  text-align: center;
  margin-inline: auto;
  position: relative;
  z-index: 2;

  display: none;
  flex-direction: column;
  gap: 8px;

  align-items: center;
  justify-content: center;

  .overlay.visible & {
    display: flex;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}
