@import 'shared/styles/global-imports';

.rateTitle {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.tooltipHeader {
  & > * {
    color: $clr-palette-white;
    margin-bottom: $spacer-s;
    white-space: pre-line;
  }
}

.ratesWrapper {
  width: 650px;
  margin: 0;
  max-height: 345px;
  overflow-y: auto;
}

.rateItemWrapper {
  display: flex;
}

.rateItem {
  list-style: none;
}
