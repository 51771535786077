@import 'shared/styles/global-imports';

$iconSize: 40px;
$userMenuGap: 8px;

.userMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: $userMenuGap;

  &:hover {
    cursor: pointer;
  }

  & :global {
    .ant-skeleton-paragraph {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0;
    }

    .ant-skeleton-content .ant-skeleton-paragraph > li {
      margin: 0;
      align-self: flex-end;
    }

    .ant-skeleton-content li:first-child {
      height: 14px;
    }

    .ant-skeleton-content li:last-child {
      height: 12px;
    }
  }
}

.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $iconSize;
  height: $iconSize;
  background-color: $clr-palette-light-primary;
  border-radius: 50%;
}
