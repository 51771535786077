@import 'shared/styles/global-imports';

.rootWrapper {
  width: 100%;
  background-color: $clr-palette-primary-97;// TODO: REPLACE-FROM-NEW-PALETTE
  border-radius: 8px;
  padding: $spacer-xl $spacer-xl $spacer-xl;// TODO: REPLACE-FROM-NEW-PALETTE
  overflow: auto;
  position: relative;

  @include media('<=screen-tablet') {
    padding: $spacer-xl $spacer-l $spacer-xl;
  }

  @include media('<=screen-phone') {
    padding: $spacer-xl $spacer-m $spacer-xl;
  }
}

.formHeader {
  margin: 0 0 $spacer-l;
}

.formHeading {
  display: flex;
  justify-content: center;
}

.formBody {
  margin: 0 0 $spacer-l;
}

.formFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blockTimer{
  margin-top: 8px;
  color: $clr-palette-brand-55;// TODO: REPLACE-FROM-NEW-PALETTE
  margin-bottom: 0;
}

.formFooterElementHolder {
  margin-bottom: $spacer-m;
  
  &:first-child {
    min-height: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.forgotPasswordLink {
  font-size: pxToRem(14);
  color: $clr-palette-primary-25;// TODO: REPLACE-FROM-NEW-PALETTE
  transition: color 0.3s;

  &:hover {
    color: $clr-palette-primary-55;// TODO: REPLACE-FROM-NEW-PALETTE
  }
}

.forgotPasswordLinkDisabled {
  pointer-events: none;
  color: $clr-palette-text-90;// TODO: REPLACE-FROM-NEW-PALETTE
}

.submitButton {
  width: 100%;
}

.formRootMessage {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  font-size: pxToRem(14);
  line-height: $line-height-l;
  padding: 8px;
  text-align: center;
  color: $clr-palette-error-50;// TODO: REPLACE-FROM-NEW-PALETTE
  background-color: $clr-palette-error-95;// TODO: REPLACE-FROM-NEW-PALETTE
  width: 100%;
  transition: transform 0.3s;

  &.isVisible {
    transform: translateY(0%);
  }
}

// custom Button (form submit case) styles
%customAntSubmitButtonRules {
  height: 64px;
  border-radius: 8px;
  border: none;
}

// Outdates styles. TODO: remove

button.customAntSubmitButton {
  @extend %customAntSubmitButtonRules;
  
  &:global(.ant-btn-primary) {
    background-color: $clr-palette-primary-25;
  }

  &[disabled] {
    background-color: $clr-palette-primary-93; // TODO: REPLACE-FROM-NEW-PALETTE
    color: $clr-palette-white-100; // TODO: REPLACE-FROM-NEW-PALETTE
    pointer-events: none;
  }

  &:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover) {
    background-color: $clr-palette-secondary-40; // TODO: REPLACE-FROM-NEW-PALETTE
  }

  &:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active) {
    background-color: $clr-palette-secondary-35; // TODO: REPLACE-FROM-NEW-PALETTE
  }

  &:global(.ant-btn-loading) {
    &.withoutTextOnLoading > span:last-child {
      display: none;
    }
  }
}
