@import 'shared/styles/global-imports';

.customMenu {
  :global {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: $clr-palette-white;
    }

    .ant-menu-inline .ant-menu-item {
      margin: 8px 0;

      &:first-child {
        margin-top: 4px;
      }

      &:last-child {
        margin-bottom: 4px;
      }
    }

    .ant-menu-light.ant-menu-root.ant-menu-inline {
      border-right: none;
    }

    .ant-menu-item-group,
    .ant-menu-submenu,
    .ant-menu-submenu-inline,
    .ant-menu-item,
    .ant-menu-item-only-child,
    .ant-menu-sub.ant-menu-inline {
      background-color: $clr-palette-white;
      cursor: default;
    }

    .ant-menu {
      border-right: none;
    }

    .ant-menu-submenu-title {
      font-weight: 700;
      font-size: 18px;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title,
    .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
      background: $clr-palette-white;
    }
  }

  :global(.ant-menu-submenu-title) > span {
    padding-left: 20px;
  }
}
