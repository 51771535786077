@import 'shared/styles/global-imports';

.navList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  height: 100%;
}

.navItem {
  margin: 0 20px 0 0;
  display: inline-block;
  height: 100%;
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-bottom-color 0.3s;
  color: $clr-palette-white;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    border-bottom-color: $clr-palette-primary;
    color: $clr-palette-primary;
  }
}

.navItemActive {
  border-bottom-color: $clr-palette-primary;
  color: $clr-palette-primary;
}

.navLink {
  height: 100%;
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}
