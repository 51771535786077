@import 'shared/styles/global-imports';

.rootWrapper {
    display: flex;
    justify-content: space-between;
}

.creationDate {
    margin-bottom: 20px;
    display: flex;
    gap: 30px;
}

.statusInput {
    width: 140px;
}

.parentsDescriptionBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 500px;
}

.description {
    .descriptionLabel {
        margin-bottom: 5px;
    }

    .descriptionText {
        color: $clr-palette-gray;
        max-height: 120px;
        overflow: auto;
        white-space: pre-wrap;
    }
}
