@import 'shared/styles/global-imports';

.createButton {
  min-width: 60px;
  height: 60px;
  position: fixed;
  right: 84px;
  bottom: 60px;
  padding: 16px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}

.createIcon {
  fill: $clr-palette-white;
}

.buttonsBlock {
  width: 300px;
  height: 64px;
  position: fixed;
  right: 84px;
  bottom: 60px;
  display: flex;
  padding: 12px;
  gap: 10px;
  background: $clr-palette-white;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: $clr-palette-primary;
  z-index: 1;

  & button {
    width: 100%;
  }
}
