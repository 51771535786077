@import 'shared/styles/global-imports';

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $clr-palette-middle-gray;
}

.topBlock {
  display: flex;
  min-height: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 0 16px;
}

.allCheckbox {
  & :global(.ant-checkbox-indeterminate .ant-checkbox-inner:after) {
    height: 4px;
  }
}

.usersCountInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badgeWrapper {
  display: flex;
  justify-content: space-between;

  &.hidden {
    display: none;
  }
}

.badge {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 120px;

  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0 12px;
  border-radius: 100px;
  margin-right: 20px;
  border: 1px solid $clr-palette-brand;

  &.positive {
    background-color: $clr-palette-brand;
    color: $clr-palette-extra-light-primary;
  }

  &.negative {
    color: $clr-palette-brand;
  }
}

.countBadge {
  font-weight: $font-weight-regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResultCount {
  margin: 0;
  font-size: 16px;
  font-weight: 400;

  & .selectedUsers {
    color: $clr-palette-primary;
    font-weight: 700;
  }
}

.searchWrapper {
  padding: 12px;
}

.pagination {
  margin: auto;
}

.showPeoples {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  margin-bottom: 0 0 16px;

  & > li {
    display: block;
    list-style: none;
    margin: 0;
  }
}

ul.list {
  padding: 0;
  margin: 0;
}
