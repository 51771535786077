@import 'shared/styles/global-imports';
@import "../../UserOverviewWidget.module";

.columnHeadersWrapper {
  height: $user-overview-widget-list-heading-size;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 8px;
  flex: 1;

  & .columnHeader, .firstColumn {
    min-width: 85px;
    color: $clr-palette-gray;
    padding: 0 8px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    font-weight: 400;
  }

  & .firstColumn {
    min-width: 30%;
  }
}

