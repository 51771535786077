.pagination {
  li {
    button {
      line-height: 0;
    }
  }

  &:global(.ant-pagination .ant-pagination-item-active) {
    border: none;
  }
}
