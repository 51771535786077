.customTabs :global {
  height: 100%;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-tabs-content {
    height: 100%;
    overflow: hidden;
  }

  .ant-tabs-tabpane {
    overflow: hidden;
    height: 100%;
  }
}
