@import 'shared/styles/global-imports';

.rowContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  border: 1px solid;
  border-radius: 6px;
  padding: 8px 4px;
}

.positionName {
  margin: 0;
  flex-grow: 2;
  max-width: 300px;
}

.currentAssignee {
  flex-grow: 1;
  max-width: 300px;
  display: flex;
}

.currentAssignee,
.positionName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  margin-right: 16px;
}

.countText {
  flex-grow: 1;
}

.pencilButton {
  display: flex;
  align-self: stretch;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 $spacer-s;

  .pencilIcon {
    fill: $clr-palette-primary;
    transition: ease 0.15s;
  }

  &:hover {
    padding-bottom: 2px;

    .pencilIcon {
      transform: scale(1.2);
      fill: $clr-palette-light-primary;
    }
  }
}

.accordionHeading {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.headingArrow {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;

  .closeArrow {
    transform: rotate(0deg);
    transition: all 0.3s;
  }

  .openArrow {
    transform: rotate(90deg);
    transition: all 0.3s;
  }
}

.panel {
  padding-left: 48px;
  width: 100%;

  &.panelClose {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: ease-out 0.5s;
  }

  &.panelOpen {
    opacity: 1;
    transition: ease 0.5s;
    margin-top: 10px;
    max-height: 260px;

    & > * {
      margin-bottom: 8px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.innerListHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.buttonHolder {
  & > *:not(:last-child) {
    margin-right: $spacer-s;
  }
}

.innerListWrapper {
  overflow: auto;
  max-height: 220px;
}

.assignmentList {
  margin-bottom: 8px;
}

.innerListSubHeading {
  font-weight: bold;
  height: 32px;
  line-height: 32px;
  margin-bottom: 8px;
}

