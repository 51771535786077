.formHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  max-width: 472px;
  min-height: 728px;
  position: relative;
}

.languageSelectorHolder {
  position: fixed;
  left: 60px;
  top: 60px;
}
