@import 'shared/styles/global-imports';

.accordionItem {
  margin-bottom: 8px;
  list-style: none;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
}

.rootWrapper {
  position: relative;
}

.filtersWrapper {
  margin-left: 32px;
  margin-bottom: 20px;
}


