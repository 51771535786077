@import 'shared/styles/global-imports';

.rowContainer {
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 6px;
  padding: 6px 0 6px 10px;
  height: 36px;

  & > *:not(:last-child) {
    flex: 1 1 0;
  }
}

.pencilButton {
  display: flex;
  align-self: stretch;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 $spacer-s;

  .pencilIcon {
    fill: $clr-palette-primary;
    transition: ease 0.15s;
  }

  &:hover {
    padding-bottom: 2px;

    .pencilIcon {
      transform: scale(1.2);
      fill: $clr-palette-light-primary;
    }
  }

  &[disabled] {
    cursor: not-allowed;

    .pencilIcon {
      transform: none;
      fill: $clr-palette-light-gray;
    }
  }
}
