@import 'shared/styles/global-imports';

.name {
  height: 100%;
  background: none;
  border: none;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s, text-decoration-color 0.3s;
  text-decoration: underline solid transparent 1px;
  text-underline-offset: 2px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: $clr-palette-blue;
    text-decoration-color: $clr-palette-blue;
  }
}

