@import 'shared/styles/global-imports';

.panelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $clr-palette-dark-gray;
  padding-right: 16px;

  width: 100%;

  &.selected {
    color: $clr-palette-primary;
    fill: $clr-palette-primary;
  }
}
