@import 'shared/styles/global-imports';

.modalContent {
  min-height: 732px;

  display: flex;
  flex-direction: column;
}

.modalHeader {
  margin-bottom: 24px;

  & .heading {
    margin: 0;
  }
}

.container {
  flex-grow: 1;

  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 4px;
}

.moveButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}