@import 'shared/styles/global-imports';

.settingsSelect {
  width: 148px;
  height: 32px;
  border-radius: 6px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;

  :global(.ant-select-open) {
    box-shadow: 0 0 0 1px $clr-palette-primary;
    border-radius: 8px;
  }
}

.selectWrapper {
  width: 150px;
}

.settingIcon {
  display: flex;
  align-items: center;

  .showChanged {
    margin-right: 7px;
    color: $clr-palette-gray;
    font-style: italic;
  }
}

.settingSelectDropdown {
  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      padding: 5px 12px;
      gap: 8px;
      background: $clr-palette-extra-light-primary;
    }
  }
}

.settingSelect {
  :global {
    .ant-select:not(.ant-select-customize-input),
    .ant-select-selector {
      border: none !important;
    }
  }
}

.colorView {
  :global {
    .ant-select-selector {
      background-color: $clr-palette-light-secondary !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      color: $clr-palette-secondary;
    }
  }
}

.colorEdit {
  :global {
    .ant-select-selector {
      background-color: $clr-palette-light-brand !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      color: $clr-palette-brand;
    }
  }
}

.colorNone {
  :global {
    .ant-select-selector {
      background-color: $clr-palette-white !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      color: $clr-palette-gray;
    }
  }
}

.colorMap {
  :global {
    .ant-select-selector {
      background-color: $clr-palette-extra-light-primary !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      color: $clr-palette-primary;
    }
  }
}
