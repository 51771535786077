$sidebar-width: 228px;

.rootWrapper {
  display: flex;
  height: 100%;
  gap: 32px;
}

.sidebarHolder {
  width: $sidebar-width;
  position: relative;
}

.contentHolder {
  width: calc(100% - $sidebar-width);
  position: relative;
}
