.rootWrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.topContentWrapper {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 0 32px; // TODO: implement as an std. spacer
}

.leftContentHolder {
  flex: 0 0 30%;
  margin: 0 32px 0 0; // TODO: implement as an std. spacer
}

.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
}

.pageHeading {
  padding-left: 16px;
  padding-top: 8px;
}

.rightContentHolder {
  flex: 0 0 calc(70% - 32px); // TODO: implement as an std. spacer
}

.mainContentHolder {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
