@import 'shared/styles/global-imports';

.rootWrapper {
  display: flex;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: $spacer-m;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  height: auto;
  margin: 14px 0;
  cursor: pointer;
}

.icon {
  border: 1px solid $clr-palette-black;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    left: 15px;
    height: 15px;
    width: 1px;
    background-color: $clr-palette-black;
  }

  &:before {
    bottom: 100%;
  }

  &:after {
    top: calc(100% + 1px);
  }
}

.itemWrapper {
  display: flex;
  flex-direction: row;

  & :global {
    .ant-form-item-control-input {
      height: 33px;
    }
  }

  & > * {
    margin: 0 $spacer-s 0 0 !important;
  }

  & > *:last-child {
    margin-right: 0 !important;
  }
}

.firstItem {
  margin-top: 45px;
}

.valueInput {
  width: 110px;
}

.currencyInput {
  width: 60px;
}

.dateInput {
  display: flex;
  width: 160px;
}

.errorTooltip {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 4px;
  }
}
