@import 'shared/styles/global-imports';

.backButtonArrow {
  stroke: $clr-palette-gray;
  transition: all 0.2s ease-in-out;
}

.backButton {
  border-color: $clr-palette-gray;

  &:global(.ant-btn.ant-btn-circle.ant-btn) {
    width: 30px;
    height: 30px;
    min-width: unset;
  }

  &:global(.ant-btn .ant-btn-icon) {
    line-height: 0;
  }
}

.backButton:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover) {
  border-color: $clr-palette-brand;

  & .backButtonArrow {
    stroke: $clr-palette-brand;
  }
}

.backButton:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active) {
  border-color: $clr-palette-brand;

  & .backButtonArrow {
    stroke: $clr-palette-brand;
  }
}
