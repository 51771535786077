@import 'shared/styles/global-imports';
.skeletonWrapper {
  height: 100%;

  & > :global(.ant-skeleton) {
    display: flex;
  }
}

.skeleton {
  height: 100%;

  & :global {
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-content {
      display: initial;
    }

    .ant-skeleton-content {
      margin: 0 $spacer-s $spacer-s 0;
    }

    .ant-skeleton-content .ant-skeleton-title {
      margin: 0;
      height: 100%;
    }
  }
}
