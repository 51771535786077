@import 'shared/styles/global-imports';

.switchMenu {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 10px;

  .switchMenuBlock {
    display: flex;
    gap: 10px;
  }

  .arrowBtn {
    padding: 0 10px;

    &:hover {
      svg {
        fill: $clr-palette-primary;
      }
    }
  }

  .btnActive {
    border-color: $clr-palette-primary;
    color: $clr-palette-primary;
  }
}
