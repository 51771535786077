@import 'shared/styles/global-imports';

.modalView {
  :global {
    .ant-modal-footer {
      display: none;
    }

    .ant-modal-content {
      height: 100vh;
      overflow: hidden;
    }

    .ant-modal-body {
      padding: 56px 0 33px 0;
      height: 100%;
    }
  }
}
