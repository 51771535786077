@import 'shared/styles/global-imports';

$available-color: $clr-palette-success;
$non-available-color: $clr-palette-error;

.rootWrapper {
  height: 480px;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.selectInputWrapper {
  margin-right: 16px;
}

.tableWrapper {
  border: 1px solid $clr-palette-black;
  height: 402px;
  position: relative;

  & :global {
    .ant-menu {
      box-shadow: none;
    }

    .ant-menu-vertical {
      border: none;

      & .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
      }

      & .ant-menu-item {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .ant-menu-item-active {
      background-color: $clr-palette-light-gray;
    }
  }
}

.table {
  height: 100%;
}

.tableEnter {
  opacity: 0;
}
.tableEnterActive {
  opacity: 1;
  transition: opacity 0.15s;
}
.tableExit {
  opacity: 1;
}
.tableExitActive {
  opacity: 0;
  transition: opacity 0.15s;
}

.menu {
  & :global {
    .ant-menu-item {
      display: flex;
      align-items: center;
    }

    .ant-menu-title-content {
      flex-grow: 1;
    }
  }
}

.rowContentWrapper {
  display: flex;
  justify-content: space-between;
}

.availabilityWrapper {
  width: 100px;
  display: flex;
  justify-content: center;
  margin-right: 30px;
}

.availableColor {
  color: $available-color;
}

.nonAvailableColor {
  color: $non-available-color;
}

.listSkeleton {
  & :global(.ant-skeleton-header) {
    vertical-align: middle;
  }
}

.skeletonItem {
  height: 40px;
  padding: 0 16px;
  width: auto;
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
}
