@import 'shared/styles/global-imports';

.row {
  height: 24px;
  width: 500px;
  padding: 0 20px 0 4px;

  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease-in-out 0.1s;

  &.selected {
    background-color: $clr-palette-extra-light-primary;
  }

  &.disabled {
    background-color: $clr-palette-extra-light-primary;
  }
}

.row {
  & :global(.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph),
  & :global(.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title) {
    margin: 0;
  }

  & :global(.ant-skeleton-header) {
    padding-inline: 22px 8px;
  }

  & :global(.ant-skeleton-content) {
    vertical-align: middle;
  }
}

.transferItem {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 48%;

  color: $clr-palette-dark-gray;

  transition: color 0.2s ease-in-out 0.1s;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.role {
  color: $clr-palette-gray;
  width: 48%;
}

.row.disabled .transferItem {
  color: $clr-palette-light-gray;
}

.tooltipTitle {
  color: $clr-palette-black;
}

.avatar {
  flex-shrink: 0;
}
