@import 'shared/styles/global-imports';

.container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: fit-content;
  min-width: 1000px;
}

.header {
  margin-bottom: $spacer-xl;

  h2 {
    margin: 0;
    white-space: nowrap;
  }

  h3 {
    white-space: break-spaces;
  }
}

.subtitle {
  font-style: italic;
  font-weight: normal;
  margin: 0;
}

.children {
  margin-bottom: $spacer-xl;
}

.footer {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
}

.mainButtonHolder > *:first-child {
  margin-right: $spacer-m;
}
