@import 'shared/styles/global-imports';

.demandWidgetHolder {
  flex: 1 0 calc(50% - 20px);
  background-color:$clr-palette-white;
  border-radius: 6px;
  height: 100%;
  overflow-y: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headerDemand {
  font-weight: 400;
  margin: 0;
}
