@import 'shared/styles/global-imports';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: $clr-palette-brand;
  padding: 0 40px;
  min-height: 76px;
  gap: 40px;
}

.logoHolder {
  flex: 0 1 auto;
  display: flex;
  align-items: center;

  @include media('<=screen-desktop') {
    flex: 1;
  }

}

.navHolder {
  flex: 1 0 auto;
  height: 100%;

  @include media('<=screen-desktop') {
    display: none;
  }
}

.rightSectionHolder{
  display: flex;
  gap: 16px;
}

.languageSelectorHolder {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userMenuHolder {
  flex: 0 0 auto;
}

.navbarMenuHolder{
  flex: 0 0 auto;
  display: none;

  @include media('<=screen-desktop') {
    display: flex;
  }
}
