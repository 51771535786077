h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
  font-weight: bold;
  margin: 0;
  color: $clr-headings-base;// TODO: REPLACE-FROM-NEW-PALETTE
  line-height: $headings-line-height;
}

pre{
  font-family: inherit;
}

h1 {
  font-size: pxToRem($h1-font-size);

  // @include media('>=desktop') {
  //   font-size: $h1-font-size-lg;
  // }
}

h2 {
  font-size: pxToRem($h2-font-size);

  // @include media('>=desktop') {
  //   font-size: $h2-font-size-lg;
  // }
}

h3 {
  font-size: pxToRem($h3-font-size);

  // @include media('>=desktop') {
  //   font-size: $h3-font-size-lg;
  // }
}

h4 {
  font-size: pxToRem($h4-font-size);

  // @include media('>=desktop') {
  //   font-size: $h4-font-size-lg;
  // }
}

h5 {
  font-size: pxToRem($h5-font-size);

  // @include media('>=desktop') {
  //   font-size: $h5-font-size-lg;
  // }
}

h6 {
  font-size: pxToRem($h6-font-size);

  // @include media('>=desktop') {
  //   font-size: $h6-font-size-lg;
  // }
}

p {
  margin: 0 0 1rem;
  line-height: pxToRem(22);
  font-size: pxToRem($font-size-base-value);
  color: $clr-partition-base;// TODO: REPLACE-FROM-NEW-PALETTE
}

a {
  color: $clr-link-base;// TODO: REPLACE-FROM-NEW-PALETTE
  text-decoration: none;
  cursor: pointer;
  transition: color $animation-speed;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $clr-link-hover-base;// TODO: REPLACE-FROM-NEW-PALETTE
  }
}

ul {
  margin: 0;
  padding: 0;
  margin-left: pxToRem($font-size-base-value);
  font-size: pxToRem($font-size-base-value);
  line-height: pxToRem(22);
  color: $clr-list-base;// TODO: REPLACE-FROM-NEW-PALETTE
}

ol {
  margin: 0;
  padding: 0;
  margin-left: pxToRem($font-size-base-value);
  font-size: pxToRem($font-size-base-value);
  line-height: pxToRem(22);
  color: $clr-list-base;// TODO: REPLACE-FROM-NEW-PALETTE
}

li {
  color: $clr-list-item-base;// TODO: REPLACE-FROM-NEW-PALETTE
  margin-bottom: pxToRem(12);
}
