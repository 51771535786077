.saveButton {
  width: 120px;
}

.hideButton {
  &:global(.ant-btn) {
    display: none;
  }
}

.modalContent {
  min-width: 1200px;
}
