@import 'shared/styles/global-imports';

.antLayout {
  background-color: $layout-body-background;
  height: 100%;
}

.mainContent {
  overflow: auto;
  padding: 32px;

  @include media('<=screen-phone') {
    padding: $page-top-gap-s $page-side-gap-s $page-bottom-gap-s;
  }
}
