@import 'shared/styles/global-imports';

.rowContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0;
  border: 1px solid;
  border-radius: 6px;
  padding: 6px 0 6px 10px;
}

.rowContent {
  display: grid;
  width: 100%;
  grid-template-columns: 300px 50% 50%;

  p {
    margin: 0;
  }
}

.rightBlock {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

.pencilButton {
  display: flex;
  align-self: stretch;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 $spacer-s;

  .pencilIcon {
    fill: $clr-palette-primary;
    transition: ease .15s;
  }

  &:hover {
    padding-bottom: 2px;

    .pencilIcon {
      transform: scale(1.2);
      fill: $clr-palette-light-primary;
    }
  }
}
