@import 'shared/styles/global-imports';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: $spacer-m;
}

.leftBlock {
  max-width: 50%;
  display: flex;
  align-items: flex-start;

  h2 {
    line-height: 1;
  }

  @media only screen and (max-width: 768px) {
    & {
      width: 100%;
    }
  }
}

.breadcrumbSkeleton {
  & :global {
    .ant-skeleton-content .ant-skeleton-title {
      height: 22px;
    }

    .ant-skeleton-content .ant-skeleton-title {
      margin-top: 0;
    }

    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 0;
    }

    h3 {
      margin: 0;
    }
  }
}

.breadcrumbWrapper {
  li {
    margin: 0;
    display: flex;
  }

  & :global {
    .ant-breadcrumb-link,
    .ant-breadcrumb-link > a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }

    .ant-breadcrumb-link {
      max-width: 200px;
    }

    .ant-breadcrumb-link > a {
      max-width: 120px;
    }
  }
}

.backIcon {
  margin-right: $spacer-s;
}

.children {
  min-width: 768px;
}
