@import 'shared/styles/global-imports';

.timelineWrapper {
  display: flex;
  align-items: center;
  align-content: stretch;
  min-width: 0;
}

.timelineItem {
  overflow: hidden;
  width: 100%;
}

.timelineItem > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.timelineItem:not(.emptyTimelineItem) + .timelineItem {
  border-left: 1px solid $clr-palette-gray;
}
