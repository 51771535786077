@import 'shared/styles/global-imports';

.content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow-y: auto;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .moveButton {
    align-self: flex-end;
    min-width: 120px;
  }
}

.userListWrapper {
  padding: 0 12px;
  border: 1px solid $clr-palette-gray;
  border-radius: 4px;

  overflow-y: auto;
  height: 400px;
}
