@import 'shared/styles/global-imports';

.optionMenu {
  width: 157px;
  cursor: pointer;

  svg {
    margin-top: -3px;
  }

  .options {
    margin-left: 10px;
  }
}

.optionMenu:not([aria-disabled='true']) {
  .optionDelete {
    color: $clr-palette-error;
  }
}

.menuKebab:not(.disabledMenu) {
  cursor: pointer;
}

.openMenu {
  color: $clr-palette-primary;
}

.closeMenu {
  color: $clr-palette-middle-gray;
}
