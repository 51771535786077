@import 'shared/styles/global-imports';

.rootWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $clr-palette-dark-gray;

  :global {
    .ant-row {
      margin: 0;
    }
  }
}

.filtersTitle {
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 12px;
}

.filtersBlock {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 5px;
  padding-right: 8px;
}

.formItemWithoutMargin:global(.ant-form-item) {
  margin-bottom: 0;
}

.buttonsBlock {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  align-items: center;
}

.applyButton {
  width: fit-content;
}

.checkboxesWrapper {
  gap: 8px;
  width: 0;
  white-space: nowrap;
  padding-left: 16px;
  font-weight: 400;
}

.collapseIcon {
  transition: all .3s;
  &.open {
    transform: rotate(180deg);
  }
}
