@import 'shared/styles/global-imports';

.antLayout {
  background-color: $layout-body-background;
  height: 100%;
}

.antContent {
  overflow: auto;
  padding: $page-top-gap-m $page-side-gap-m $page-bottom-gap-m;

  @include media('<=screen-phone') {
    padding: $page-top-gap-s $page-side-gap-s $page-bottom-gap-s;
  }
}
