@import 'shared/styles/global-imports';

.formItemsWrapper {
  width: 480px;
}

.nameInputWrapper {
  display: flex;
  margin-bottom: 24px;
  align-items: baseline;
}

.nameInput {
  cursor: pointer;

  &:global(.ant-input) {
    width: 100%;
  }

  &:global(.ant-input:disabled) {
    background-color: $clr-palette-white;
    user-select: none;
  }
}

.datePicker {
  width: 100%;
}

.involvements {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 105px;

  & :global(.ant-form-item-control) {
    width: 100%;
  }

  & > *:first-child {
    margin-right: $spacer-s;
  }
}

.billedInvolvementWrapper {
  & :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.clearUserModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorTooltip {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 4px;
  }
}
