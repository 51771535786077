@import 'shared/styles/global-imports';

:export {
  clrPalettePrimary25: $clr-palette-primary-25;// TODO: REPLACE-FROM-NEW-PALETTE
  clrPaletteText90: $clr-palette-text-90;// TODO: REPLACE-FROM-NEW-PALETTE
}

.logInWithGoogle {
  display: block;
}

.logInWithGoogleDisabled {
  color: $clr-palette-text-90;// TODO: REPLACE-FROM-NEW-PALETTE
  pointer-events: none;
  opacity: 50%;
}

.logInWithGoogleLoading {
  opacity: 0;
}
