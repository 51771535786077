@import 'shared/styles/global-imports';

.nameCellRootWrapper {
  display: flex;
  padding-left: 30px;

  & :global {
    .ant-avatar {
      position: absolute;
      left: 8px;
    }
  }
}
