@import 'shared/styles/global-imports';

.rowContainer {
  display: flex;
  align-items: center;
  margin: 0;
  border: 1px solid;
  border-radius: 6px;
  padding: 2px 0;
  position: relative;
}

.rowContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 2px 0 2px $spacer-s;

  & .link {
    color: $clr-palette-black;

    text-decoration: none;
    white-space: pre;
    
    transition: color 0.3s, border-color 0.3s;
    border-bottom: 1px solid transparent;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover:not(.disabled) {
      color: $clr-palette-link-blue;
      text-underline-offset: 2px;
      border-bottom-color: $clr-palette-link-blue;
    }

    &.archived {
      text-decoration: line-through;
      color: $clr-palette-middle-gray;

      &:hover:not(.disabled) {
        color: $clr-palette-gray;
        border-bottom-color: $clr-palette-gray;
      }
    }
  }
}

.rightBlock {
  display: flex;
  align-items: center;
  min-width: 300px;
  justify-content: space-between;

  > :nth-last-child(-n + 1) {
    margin-right: $spacer-s;
  }
}

.pencilButton {
  display: flex;
  align-self: stretch;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 $spacer-s;

  .pencilIcon {
    fill: $clr-palette-primary;
    transition: ease 0.15s;
  }

  &:hover {
    padding-bottom: 2px;

    .pencilIcon {
      transform: scale(1.2);
      fill: $clr-palette-light-primary;
    }
  }
}

.logo {
  padding: 8px;
}

.archiveIcon {
  position: absolute;
  top: -7px;
  left: -6px;
  width: 20px;
  height: 20px;

  fill: $clr-palette-white;
  color: $clr-palette-gray;
  background-color: $clr-palette-white;
  border-radius: 10px;
}
