@import 'shared/styles/global-imports';

.userCardWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.userData {
  display: flex;
  gap: 39px;
  min-width: 360px;
  height: fit-content;
  background-color: $clr-body-bg-light-gray;
  padding: 24px;
  margin-bottom: 24px;
}

.image {
  flex-direction: column;
  display: flex;
  align-items: center;

  .avatarSkeleton {
    display: flex;
    justify-content: center;

    :global .ant-skeleton-header {
      padding: 0;
    }
  }
}

.heading {
  margin-bottom: 8px;

  .fullName {
    font-weight: $font-weight-regular;
  }

  .workProfile {
    color: $clr-palette-gray;
    font-weight: $font-weight-normal;
  }

  .fullName, .workProfile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.userDataItems {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  height: 100%;
  gap: 4px;
  width: 100%;
}

.userDataItem {
  display: flex;
  justify-content: flex-start;
  color: $clr-palette-dark-gray;
  font-weight: $font-weight-normal;

  .userDataItemName {
    display: flex;
    justify-content: flex-start;
    min-width: 167.5px;
  }

  .userDataItemValue,
  .valueSkeleton {
    min-width: 167.5px;
    white-space: nowrap;

    :global .ant-skeleton-content {
      vertical-align: middle;
    }

    :global .ant-skeleton-paragraph,
    :global .ant-skeleton-paragraph > li {
      margin: 0;
    }

    :global .ant-skeleton-paragraph {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .userDataItemValue, .cvLink {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cvLink {
    text-decoration: none;
    white-space: nowrap;
  }
}

.content {
  padding: 0 24px;
  overflow: hidden;
}
