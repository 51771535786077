@import 'shared/styles/global-imports';

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $clr-body-bg;
  z-index: 2;

  &.translucent {
    opacity: 0.5;
  }

  &.hidden {
    transition: opacity 0.3s;
    pointer-events: none;
    opacity: 0;
  }
}

.loaderFallbackText {
  text-indent: -9999em;
}

.loader:global(.ant-spin) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
