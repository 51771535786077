@import 'shared/styles/global-imports';

.moveButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 2px;

  background-color: $clr-palette-primary;
  border: 1px solid $clr-palette-primary;
  cursor: pointer;

  transition: all 0.2s ease-in-out .1s;
  fill: $clr-palette-white;
  fill-opacity: 1;

  &:disabled {
    background-color: $clr-palette-extra-light-gray;
    border: 1px solid $clr-palette-extra-light-gray;
    cursor: not-allowed;

    fill: $clr-palette-black;
    fill-opacity: 0.25;
  }
}
