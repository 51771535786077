@import 'shared/styles/global-imports';

$timeline-info-width: 120px;

.container {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rootWrapper {
  position: relative;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rootWrapper,
.timelinesWrapper {
  display: flex;
  flex-direction: column;
}

.timelinesWrapper {
  gap: 0;
  z-index: 1;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: $clr-scrollbar-handle $clr-scrollbar-track;
}

.monthsWrapper {
  margin-left: $timeline-info-width;
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  background-color: $clr-palette-extra-light-gray;
}

.monthsWrapper > div {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.timelineSkeleton {
  display: flex;
  flex-direction: column;

  :global(.ant-skeleton-content .ant-skeleton-title) {
    padding: 10px 0;
    margin-bottom: 10px;
  }
}

.timelineWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.link {
  transition: all 0.2s;
}

.link:hover {
  background-color: $clr-palette-extra-light-primary;
}

.link:hover .positionName {
  color: $clr-palette-dark-gray;
}

.link:visited,
.link:focus,
.link:hover {
  text-decoration: none;
}

.timeline,
.timelineInfo {
  flex-grow: 1;
}

.timeline {
  width: 100%;
  max-width: calc(100% - $timeline-info-width);
}

.timelineInfo {
  max-width: $timeline-info-width;
  margin: 8px 0;
  width: $timeline-info-width;
}

.timelineInfo > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.timelineContent {
  background-color: $clr-palette-light-primary;
  padding: 1px 4px;
}

.accountName {
  font-weight: 700;
  padding-bottom: 4px;
}

.projectName {
  padding-bottom: 4px;
}

.accountName, .projectName {
  max-width: $timeline-info-width;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.positionName {
  color: $clr-palette-gray;
  padding: 0 8px;
}

.monthBlock:not(:first-child)::after {
  content: "";
  display: block;
  top: 0;
  position: absolute;
  height: 100%;
  border-right: 1px dashed $clr-palette-light-gray;
}

.currentMonth {
  border-bottom: 2px solid $clr-palette-primary ;
}

/* for Safari */
.timelinesWrapper::-webkit-scrollbar {
  width: 2px;
}
