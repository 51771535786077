@import 'shared/styles/global-imports';

.rootWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
}

.logoHolder {
  margin: 0 0 50px;
}

.errorHeading {
  margin: 0 0 20px;
}

.errorMessage {
  margin: 0 0 50px;
  max-height: 300px;
  max-width: 800px;
  overflow: scroll;
}

.customButton {
  background-color: $clr-palette-primary;

  &:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover) {
    background-color: $clr-palette-light-primary;
  }

  &:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active) {
    background-color: $clr-palette-dark-primary;
  }
}
