@import 'shared/styles/global-imports';

.rootWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 20px;
}

.widgetHolder {
  flex: 1 0 calc(50% - 20px);
  background-color: $clr-palette-white;
  box-shadow: 0px 3px 6px -4px lighten($clr-palette-black, 88%),0px 6px 16px lighten($clr-palette-black, 92%), 0px 9px 28px 8px lighten($clr-palette-black, 95%);
  border-radius: 6px;
  overflow-y: hidden;
  position: relative;
  height: 50%;
  min-height: 380px;
}
