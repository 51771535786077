@import 'shared/styles/global-imports';

.noUsers {
  width: 80px;
  height: 24px;
  border-radius: 30px;
  align-items: center;
  margin: 0px 10px;
  font-size: 13px;
  color: $clr-palette-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.usersAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $clr-palette-white;
  margin: 0px -3px;
  background: $clr-palette-primary;
  box-sizing: border-box;
}

.usersAvatarDefault {
  background: $clr-palette-light-gray;
}

.tableWrapper {
  display: flex;
}

.topContentWrapper {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $clr-palette-extra-light-gray;
  border-radius: 100px;
  padding: 8px;

  &.clickable {
    cursor: pointer;
  }
}
