@import 'shared/styles/global-imports';

$icon-size: 20px;

.tabWrapper {
  height: 100%;
  overflow: hidden;
}

.permissionTable {
  height: 100%;

  & :global {
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container {
      height: 100%;
      scrollbar-color: unset;
    }

    .ant-table-header colgroup col:last-child {
      // Idk why but without it antd add horizontal scroll to header
      display: none;
    }

    .ant-table-header thead tr th:last-child {
      // remove shadow that hide the last column's left border
      box-shadow: none;
    }

    .ant-table-body {
      // no way to avoid !important. Antd adding overflow-y: scroll using inline style
      overflow-y: auto !important;

      & >:first-child {
        padding-bottom: 1px;
      }
    }
  }
}

.primaryIcon {
  color: $clr-palette-brand;
  width: $icon-size;
  height: $icon-size;
}

.checkMarkIcon {
  color: $clr-palette-success;
  width: $icon-size;
  height: $icon-size;
}

.skeletonTableItem {

  & :global {
    .ant-skeleton-content .ant-skeleton-title {
      margin: 0;
    }
  }
}
