@import 'shared/styles/global-imports';

.acronym {
  user-select: none;
  line-height: 0;
}

:export {
  clrAvatar: $clr-palette-gray;
}
