@import 'shared/styles/config/color-config';

// Aux variables
$clr-palette-dark-primary: lighten($clr-palette-primary-zero-lightness, 45%);
$clr-palette-primary: lighten($clr-palette-primary-zero-lightness, 53%);
$clr-palette-light-primary: lighten($clr-palette-primary-zero-lightness, 80%);
$clr-palette-extra-light-primary: lighten($clr-palette-primary-zero-lightness, 97%);

$clr-palette-info: lighten($clr-palette-info-zero-lightness, 55%);
$clr-palette-light-info: lighten($clr-palette-info-zero-lightness, 90%);

$clr-palette-success: lighten($clr-palette-success-zero-lightness, 44%);
$clr-palette-light-success: lighten($clr-palette-success-zero-lightness, 90%);
$clr-palette-extra-light-success: lighten($clr-palette-success-zero-lightness, 96%);

$clr-palette-processing: lighten($clr-palette-processing-zero-lightness, 53%);

$clr-palette-error: lighten($clr-palette-error-zero-lightness, 55%);
$clr-palette-light-error: lighten($clr-palette-error-zero-lightness, 90%);
$clr-palette-extra-light-error: lighten($clr-palette-error-zero-lightness, 97%);

$clr-palette-warning: lighten($clr-palette-warning-zero-lightness, 53%);
$clr-palette-light-warning: lighten($clr-palette-warning-zero-lightness, 83%);
$clr-palette-extra-light-warning: lighten($clr-palette-warning-zero-lightness, 97%);

$clr-palette-black: lighten($clr-palette-black-zero-lightness, 0%);

$clr-palette-white: lighten($clr-palette-white-zero-lightness, 100%);

$clr-palette-dark-gray: lighten($clr-palette-gray-zero-lightness, 26%);
$clr-palette-gray: lighten($clr-palette-gray-zero-lightness, 55%);
$clr-palette-middle-gray: lighten($clr-palette-gray-zero-lightness, 75%);
$clr-palette-light-gray: lighten($clr-palette-gray-zero-lightness, 85%);
$clr-palette-extra-light-gray: lighten($clr-palette-gray-zero-lightness, 96%);

$clr-palette-brand: lighten($clr-palette-brand-zero-lightness, 25%);
$clr-palette-light-brand: lighten($clr-palette-brand-zero-lightness, 97%);

$clr-palette-secondary: lighten($clr-palette-secondary-zero-lightness, 35%);
$clr-palette-light-secondary: lighten($clr-palette-secondary-zero-lightness, 97%);

$clr-palette-blue: lighten($clr-palette-blue-zero-lightness, 42%);

$clr-palette-beige: lighten($clr-palette-beige-zero-lightness, 83%);
