@import 'shared/styles/global-imports';

// custom Form styles
.customAntForm {
  :global {
    label.ant-form-item-required {
      font-size: pxToRem(16);
    }

    .ant-form-item-explain-error {
      font-size: pxToRem(12);
      padding-top: 2px;

      &:before {
        content: ' ';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: transparent url('~shared/assets/icons/exclamation-circle.svg') center / 14px 14px no-repeat;// TODO: REPLACE-FROM-NEW-PALETTE
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
    }
  }
}

// custom Form.Item styles
.customAntFormItem {
  // Keeps bottom padding on form item wrapper during validation (last input in the form case)
  &.withPaddingOnError {
    padding-bottom: 24px;
  }
}

// custom Input styles
%customAntInputRules {
  height: 64px;
  font-size: $font-size-regular;
  line-height: $line-height-base;
  border-radius: 8px;
}

input.customAntInput {
  @extend %customAntInputRules;
}

// custom Input.Password wrapper styles
:global(.ant-input-password).customAntPasswordInput {
  @extend %customAntInputRules;

  :global(.ant-input-password-icon) {
    &:hover {
      cursor: pointer;
    }
  }
}
