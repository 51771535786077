$clr-palette-white-0: hsl(0, 0%, 0%);
$clr-palette-black-0: #000000;

// Ant set (zero lightness)
$clr-palette-primary-0: hsl(215, 50%, 0%);
// $clr-palette-info: #;
$clr-palette-success-0: hsl(127, 67%, 0%);
// $clr-palette-processing: #;
$clr-palette-error-0: hsl(0, 86%, 0%);
$clr-palette-warning-0: hsl(48, 100%, 0%);

// Extended set (zero lightness)
$clr-palette-text-0: hsl(220, 10%, 0%);
$clr-palette-secondary-0: hsl(187, 66%, 0%);
$clr-palette-brand-0: hsl(31, 78%, 0%);

// Aux variables
$clr-palette-primary-25: lighten($clr-palette-primary-0, 25%);
$clr-palette-primary-35: lighten($clr-palette-primary-0, 35%);
$clr-palette-primary-45: lighten($clr-palette-primary-0, 45%);
$clr-palette-primary-55: lighten($clr-palette-primary-0, 55%);
$clr-palette-primary-65: lighten($clr-palette-primary-0, 65%);
$clr-palette-primary-75: lighten($clr-palette-primary-0, 75%);
$clr-palette-primary-85: lighten($clr-palette-primary-0, 85%);
$clr-palette-primary-93: lighten($clr-palette-primary-0, 93%);
$clr-palette-primary-97: lighten($clr-palette-primary-0, 97%);

$clr-palette-success-50: lighten($clr-palette-success-0, 50%);

$clr-palette-error-50: lighten($clr-palette-error-0, 50%);
$clr-palette-error-95: lighten($clr-palette-error-0, 95%);

$clr-palette-warning-50: lighten($clr-palette-warning-0, 50%);

$clr-palette-text-15: lighten($clr-palette-text-0, 15%);
$clr-palette-text-20: lighten($clr-palette-text-0, 20%);
$clr-palette-text-30: lighten($clr-palette-text-0, 30%);
$clr-palette-text-40: lighten($clr-palette-text-0, 40%);
$clr-palette-text-50: lighten($clr-palette-text-0, 50%);
$clr-palette-text-60: lighten($clr-palette-text-0, 60%);
$clr-palette-text-70: lighten($clr-palette-text-0, 70%);
$clr-palette-text-80: lighten($clr-palette-text-0, 80%);
$clr-palette-text-90: lighten($clr-palette-text-0, 90%);
$clr-palette-text-93: lighten($clr-palette-text-0, 93%);
$clr-palette-text-97: lighten($clr-palette-text-0, 97%);

$clr-palette-secondary-35: lighten($clr-palette-secondary-0, 35%);
$clr-palette-secondary-40: lighten($clr-palette-secondary-0, 40%);
$clr-palette-secondary-50: lighten($clr-palette-secondary-0, 50%);
$clr-palette-secondary-60: lighten($clr-palette-secondary-0, 60%);
$clr-palette-secondary-70: lighten($clr-palette-secondary-0, 70%);
$clr-palette-secondary-80: lighten($clr-palette-secondary-0, 80%);
$clr-palette-secondary-90: lighten($clr-palette-secondary-0, 90%);
$clr-palette-secondary-93: lighten($clr-palette-secondary-0, 93%);
$clr-palette-secondary-97: lighten($clr-palette-secondary-0, 97%);

$clr-palette-brand-55: lighten($clr-palette-brand-0, 55%);
$clr-palette-brand-60: lighten($clr-palette-brand-0, 60%);
$clr-palette-brand-65: lighten($clr-palette-brand-0, 65%);
$clr-palette-brand-70: lighten($clr-palette-brand-0, 70%);
$clr-palette-brand-85: lighten($clr-palette-brand-0, 85%);
$clr-palette-brand-96: lighten($clr-palette-brand-0, 96%);
$clr-palette-brand-90: lighten($clr-palette-brand-0, 90%);
$clr-palette-brand-95: lighten($clr-palette-brand-0, 95%);

$clr-palette-white-100: lighten($clr-palette-white-0, 100%);
$clr-palette-white-rgba-60: rgba($clr-palette-white-100, 60);
