@import 'shared/styles/global-imports';

.rootWrapper {
  height: 480px;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.tableWrapper {
  border: 1px solid $clr-palette-black;
  height: 402px;
  position: relative;

  & :global {
    .ant-menu {
      box-shadow: none;
    }

    .ant-menu-vertical {
      border: none;

      & .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
      }

      & .ant-menu-item {
        margin-top: 0;
        margin-bottom: 0;

        & .ant-avatar {
          flex-shrink: 0;
        }

        & .ant-menu-title-content {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .ant-menu-item-active {
      background-color: $clr-palette-light-gray;
    }
  }
}

.table {
  height: 100%;
  overflow: auto;
}

.tableEnter {
  opacity: 0;
}
.tableEnterActive {
  opacity: 1;
  transition: opacity 0.15s;
}
.tableExit {
  opacity: 1;
}
.tableExitActive {
  opacity: 0;
  transition: opacity .15s;
}

.listSkeleton {
  & :global(.ant-skeleton-header) {
    vertical-align: middle;
  }
}

.skeletonItem {
  height: 40px;
  padding: 0 16px;
  width: auto;
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
}

.menu {
  & :global(.ant-menu-item){
    display: flex;
    align-items: center;
  }
}
