.imageUploader {
  height: 100%;

  // Just don't touch it... Someday antd will implement circle-card
  &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select),
  &:global(.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item),
  & :global(.ant-upload-list-item-image),
  &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item::before) {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select > .ant-upload),
  & :global(.ant-upload-list),
  &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item-container) {
    height: 100%;
  }
}
