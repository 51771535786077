@import 'shared/styles/global-imports';

.rootWrapper {
  position: relative;
}

.assignmentItemWrapper {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
