@import 'shared/styles/global-imports';

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

a.wrapper:hover .demandItems {
  border-color: $clr-palette-light-primary;
  background-color: $clr-palette-extra-light-primary;
  border-radius: 4px;
}

.demandItems {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $clr-palette-extra-light-gray;
  border-bottom: 1px solid $clr-palette-extra-light-gray;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  font-weight: 400;
  transition: background-color .3s linear;
}

.demandLogoStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1.5;
  max-width: 30%;
  padding: 0px 8px;
}

.demandStatus {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  min-width: 8px;
}

.demandWidgetText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  min-width: 24px;
  max-width: 135px;
  color: $clr-palette-black;
}

.projectName {
  display: flex;
  flex: 1.5;
  padding: 0 8px;
  max-width: 25%;
}

.recruiter {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 25%;
  gap: 2px;
  color: $clr-palette-black;
}

.demandDesirableDate {
  flex: 1;
  display: flex;
  padding: 0 8px;
  max-width: 20%;
}

.avatar {
  min-width: 24px;
}
