@import 'shared/styles/global-imports';

.pagination {
  display: flex;
  justify-content: flex-end;
}

.list { 
  margin: 0 0 30px;
  
  >:nth-last-child(n + 2) {
    margin-bottom: 8px;
  }
}
