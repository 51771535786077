@import 'shared/styles/global-imports';

.itemWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border-top: 1px solid $clr-palette-extra-light-gray;
  border-bottom: 1px solid $clr-palette-extra-light-gray;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  transition: background-color .3s linear;

  & .infoItem, .nameWrapper {
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    font-weight: 400;
  }

  .infoItem {
    min-width: 85px;
  }

  &:hover {
    border-radius: 4px;
    border-color: $clr-palette-light-primary;
    background-color: $clr-palette-extra-light-primary;
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  min-width: 30%;
}

.avatar {
  margin-right: 8px;
}

.skeletonItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & :global {
    .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph,
    .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-header {
      padding-right: 8px;
    }

    .ant-skeleton {
      display: flex;
      align-items: center;
    }
  }
}

