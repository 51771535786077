@import 'shared/styles/global-imports';

.breadcrumb {
  li::before {
    content: none;
    display: none;
  }

  & ol li span .breadcrumbLink:not(:hover) {
    color: $clr-palette-gray;
  }

  & ol li span .breadcrumbText {
    color: $clr-palette-dark-gray;
  }

  :global {
    span.ant-breadcrumb-link {
      white-space: pre;

      & a {
        white-space: pre;
      }
    }
  }
}
