@import 'shared/styles/global-imports';

.searchInput {
  max-width: 212px;

  .searchIcon {
    color: $clr-palette-dark-gray;
  }

  :global .ant-input-prefix {
    margin-right: 10px;
  }
}
