@import 'shared/styles/global-imports';

.formItemsWrapper {
  width: 500px;
}

.mainBlock {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
  align-items: center;
}

.logoWrapper {
  $picture-size: 75px;

  height: $picture-size;
  width: $picture-size;

  & :global {
    .ant-row,
    .ant-form-item-control-input-content,
    .ant-form-item-control-input {
      height: 100%;
    }
  }
}

.nameInput {
  flex: 1;
}

.descriptionInput {
  width: 100%;
}
