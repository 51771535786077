.paddinglessCollapse {
  &:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding: 0;
  }

  &:global(.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
    right: 0;
  }
  & :global {
    .ant-collapse-item > .ant-collapse-header[role='button'] {
      padding: 8px 0;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow[role='img'] {
      right: 0;
    }
  }
}
