@import 'shared/styles/global-imports';

.checkboxRightLabel {
  flex-direction: row-reverse;
  width: 100%;

  & :global {
    .ant-checkbox+span {
      padding-left: 0;
    }

    .ant-checkbox+span {
      width: 100%;
    }
  }
}
