@import 'shared/styles/global-imports';

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.listItem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 6px;
  gap: 16px;
  min-height: 60px;
  width: 100%;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $clr-palette-light-gray;
  }

  & .leftBlock {
    max-width: 30%;
    flex: 1;
    display: flex;
    gap: 8px;
    align-items: center;

    & .avatar {
      min-width: 24px;
    }

    & .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .tags {
    display: flex;
    max-width: 60%;
    flex: 1;
    row-gap: 4px;
    gap: 4px;
    flex-wrap: wrap;
  }
}

.tag {
  &:global(.ant-tag) {
    width: min-content;
    display: inline-flex;
    align-items: center;
  }
}
