@import 'shared/styles/global-imports';

.virtualTable {
  &:global(.ant-table-wrapper .ant-table) {
    scrollbar-color: unset;
  }

  & :global {

    .ant-table-summary > tr > .ant-table-cell {
      padding: 8px;
    }
    .ant-table-row > .ant-table-cell:last-child {
      border-right: 1px solid $clr-palette-extra-light-gray;
    }

    .ant-table-tbody > .ant-table-row > .ant-table-cell {
      padding: 8px;
      font-weight: 400;
    }

    .ant-table-thead .ant-table-cell {
      padding: 8px;
      font-weight: 500;
    }
  }
}

.scrollableDiv {
  height: 100%;
  overflow: auto;
}

