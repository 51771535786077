@import 'shared/styles/global-imports';

.localPermissions {
  position: relative;
  width: 800px;
  padding: 8px;
  margin-top: 12px;
  height: 300px;
  overflow-y: auto;
  border-radius: 2px;

  border: 1px solid $clr-palette-light-gray;
}
