@import 'shared/styles/global-imports';

.rootWrapper {
  display: flex;
  width: 100%;
}

.sidebarHolder {
  margin-top: 72px;
  min-width: 300px;
  padding-right: 2px;
}

.columnsHolder {
  width: calc(100% - 300px);
  display: flex;
}

.tableContainer {
  display: flex;
}

.unpinnedTypeContainer {
  flex: 1;
  overflow-y: auto;
}

.shadow {
  z-index: 1;
  box-shadow: 10px 0px 10px -7px $clr-palette-extra-light-gray;
}
