@import 'shared/styles/global-imports';

.rootWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.formItems {
  width: 40%;
  min-width: 400px;
}

.rateWrapper {
  width: 60%;
  min-width: 400px;
}
