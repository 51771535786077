@import 'shared/styles/global-imports';
html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  min-width: $page-min-width;
  color: $clr-body-color;
  background-color: $clr-body-bg;
  font-family: $font-family-base;
  font-size: pxToRem($font-size-base-value);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include media('<=screen-desktop') {
    min-width: 1120px;
  }
}

#root {
  height: 100%;
}

pre {
  margin-top: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-track {
  background-color: $clr-scrollbar-track;
  border-radius: $scrollbar-border-radius;
}

::-webkit-scrollbar-thumb {
  background-color: $clr-scrollbar-handle;
  border-radius: $scrollbar-border-radius;
}

::-webkit-scrollbar-track:hover {
  background-color: $clr-scrollbar-track-hover;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $clr-scrollbar-handle-hover;
}

.ant-modal .ant-modal-close {
  height: 24px;
  width: 24px;
  top: unset;
}

.ant-modal .ant-modal-confirm-paragraph .ant-modal-confirm-content, .ant-modal-confirm-title {
  padding-inline-end: 24px;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  inset-inline-end: 12px;
}

* {
  @supports not (selector(::-webkit-scrollbar)) {
    scrollbar-color: $clr-scrollbar-handle $clr-scrollbar-track;
    scrollbar-width: thin;
  }
}
