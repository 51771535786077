@import 'shared/styles/global-imports';

.container {
  width: 100%;
  height: 100%;
  padding: 8px;

  justify-content: center;

  color: $clr-palette-middle-gray;
  font-weight: 700;
}
